import { DISPLAY_EVENT_STATUS } from "../common/constants/displayEventStatus";
import { EVENT_STATUS } from "../common/enums/eventStatus.enum";

/**
 * A mapping of display event statuses to their corresponding color codes.
 */
export const colorMap = {
  [DISPLAY_EVENT_STATUS.ACTION_REQUIRED]: '#FF9E16',
  [DISPLAY_EVENT_STATUS.DISCARD]: '#E64848',
  [DISPLAY_EVENT_STATUS.APPROVED]: '#24C142',
  [DISPLAY_EVENT_STATUS.ONGOING]: '#24C142',
  [DISPLAY_EVENT_STATUS.REGISTRATION_STARTED]: '#24C142',
  [DISPLAY_EVENT_STATUS.SUBMISSION_STARTED]: '#24C142',
  [DISPLAY_EVENT_STATUS.REGISTERED]: '#24C142',
  [DISPLAY_EVENT_STATUS.SUBMITTED]: '#24C142',
  [DISPLAY_EVENT_STATUS.REQUESTED]: '#FF9E16',
  [DISPLAY_EVENT_STATUS.DRAFT]: '#7e7e7e',
  [DISPLAY_EVENT_STATUS.REGISTRATION_CLOSED]: '#7e7e7e',
  [DISPLAY_EVENT_STATUS.SUBMISSION_CLOSED]: '#7e7e7e',
  [DISPLAY_EVENT_STATUS.UPCOMING]: '#00ADF0',
};


/**
 * A mapping of event statuses to their corresponding display event statuses.
 *
 * @property {string} [EVENT_STATUS.APPROVED] - Approved event status.
 * @property {string} [EVENT_STATUS.REQUESTED] - Requested event status.
 * @property {string} [EVENT_STATUS.ACTION_REQUIRED] - Action required event status.
 * @property {string} [EVENT_STATUS.REJECTED] - Rejected event status.
 * @property {string} [EVENT_STATUS.DISCARD] - Discard event status.
 */
export const statusMap: { [key in EVENT_STATUS]: string } = {
  [EVENT_STATUS.APPROVED]: DISPLAY_EVENT_STATUS.APPROVED,
  [EVENT_STATUS.REQUESTED]: DISPLAY_EVENT_STATUS.REQUESTED,
  [EVENT_STATUS.ACTION_REQUIRED]: DISPLAY_EVENT_STATUS.ACTION_REQUIRED,
  [EVENT_STATUS.REJECTED]: DISPLAY_EVENT_STATUS.DISCARD,
  [EVENT_STATUS.DISCARD]: DISPLAY_EVENT_STATUS.DISCARD,
};
